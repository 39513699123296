<template>
  <div>
    <v-card outlined>
      <v-card-title>
        {{ $t('alnwdat-alfrayh') }}
        <v-spacer></v-spacer>
        <v-btn @click="openAddDialog" color="primary">
          <v-icon>add_circle_outline</v-icon>
          {{ $t('add') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="mytable"
          :items-per-page="$global.state.filter.pageSize"
          :items="$global.state.nodes"
          :loading="$global.state.loading"
          :headers="headers"
          hide-default-footer
          :loading-text="$t('loading')"
          :no-data-text="$t('no-data')"
          :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
        >
          <template v-slot:item.group.name="{ item }">
            <v-chip>{{ item.group.name }}</v-chip>
          </template>
          <template v-slot:item.expireDays="{ item }">
            <v-chip
              >{{ item.expireDays }} {{ $t('day') }} || {{ item.expireHours }}{{ $t('saah') }}</v-chip
            >
          </template>
          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
                  <v-icon color="error"> delete </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('hthf') }}</span>
            </v-tooltip>

            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="openEditDialog(item)" v-on="on" icon>
                  <v-icon color="info"> edit </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('edit') }}</span>
            </v-tooltip>
            <v-btn outlined @click="openActionsDialog(item)" color="primary">
              {{ $t('alfwrmh') }}
            </v-btn>
          </template>
        </v-data-table>
        <Pagination />
        <FormDialog />
        <Dialog />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
    FormDialog: () => import("./formDialog.vue"),
    Dialog: () => import("./dialog.vue"),
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("alism"),
          value: "name",
        },
        {
          text: this.$t('alwsf'),
          value: "name",
        },
        {
          text: this.$t('altslsl'),
          value: "sequence",
        },
        {
          text: this.$t('almjmwah'),
          value: "group.name",
        },
        {
          text: this.$t('mdh-alslahyh'),
          value: "expireDays",
        },
        {
          text: "",
          value: "actions",
        },
      ],
    };
  },
  
  methods: {
    getItems() {
      this.$global.dispatch(`getNodes`, this.$route.params.id);
      let items = this.$global.state.nodes;
      let lastSequence = 1;
      // sort by sequence
      items.sort((a, b) => {
        return a.sequence - b.sequence;
      });
      if (items.length > 0) {
        lastSequence = items[items.length - 1].sequence + 1;
      }
      this.$store.commit("setSequence", lastSequence);
      console.log("ddd", lastSequence);
    },
    async deleteItem(id, i) {
      await this.$service.deleteItem(id, `Node`, `delete`);
      this.$eventBus.$once(`delete`, () => {
        setTimeout(() => {
          this.getItems();
        }, 10);
      });
    },

    openAddDialog() {
      var item = {
        dialogType: "add",
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },

    openActionsDialog(item) {
      item.dialogType = "add";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setActionsDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
  mounted() {
    this.getItems();
    this.$eventBus.$on(`refresh`, () => {
      this.getItems();
    });
    // this.$global.dispatch(`getWorkFlow`);
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
  },
  watch: {
    $route: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
};
</script>
